
import { useStore } from "vuex";
import { defineComponent, computed, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
export default defineComponent({
  props: ["isLoading"],
  components: {
    Datatable,
  },
  setup() {
    const check = ref(false);
    const slidepage = ref(true);
    const slidepaynow = ref(false);
    const isLoadingPage = ref(true);
    const credit = ref({});
    const bill = ref([]);
    const reload = ref(false);
    const router = useRouter();
    async function Popuppage(item) {
      check.value = true;
      credit.value = item;
    }
    async function Done() {
      await store.commit("BillModule/" + Mutations.BILL_PAY_CHECK, {
        bill_complete: [],
        bill_fail: [],
      });
      slidepage.value = true;
      check.value = false;
    }
    const route = useRoute();
    const tableHeader = ref([
      {
        name: "No.",
        key: "no",
        sortable: false,
        align: "center",
      },
      {
        name: "Due date",
        key: "bill_due_date",
        sortingField: "status.label",
        sortable: true,
        align: "center",
      },
      {
        name: "Invoice number",
        key: "bill_number",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "Invoice type",
        key: "bill_type_name",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "Description",
        key: "bill_description",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Net Price",
        key: "bill_net_price",
        sortable: false,
        align: "center",
      },
      {
        name: "Status",
        key: "bill_status",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Action",
        key: "actions",
        sortingField: "status.label",
        sortable: false,
      },
    ]);
    const searchConfig = computed(() => {
      return store.state.BillModule.filterBillListForm; //เข้าไปที่ file axios function filrerriderform
    });
    async function toView(item) {
      isLoadingPage.value = true;
      reload.value = true;
      store.commit("setLoadingGlobal", true);
      const result = await store.dispatch(
        `BillModule/${Actions.FETCH_BILL_BY_ID}`,
        {
          transactionId: item.bill_id,
        }
      );
      store.commit("setLoadingGlobal", false);
      isLoadingPage.value = false;
      if (result == false) return;
      router.push({
        path: `/management/bill-management/${item.bill_id}`,
      });
    }
    async function toPay(credit) {
      isLoadingPage.value = true;
      let payload = [credit];
      await store.dispatch(`BillModule/${Actions.TOPUP_CREDIT_PAY}`, {
        transactionPay: payload,
      });
      isLoadingPage.value = false;
      slidepaynow.value = true;
    }
    onMounted(() => {
      if (reload.value) {
        location.reload();
      }
    });
    const store = useStore();
    const billList = computed(() => {
      return store.getters["BillModule/getBillList"].filter((val) => {
        return (
          ((searchConfig.value.bill_status != -1 &&
            val.bill_status == searchConfig.value.bill_status) ||
            searchConfig.value.bill_status == -1) &&
          (val.bill_number
            .toLowerCase()
            .includes(searchConfig.value.bill_text.toLowerCase()) ||
            val.bill_type.bill_type_name
              .toLowerCase()
              .includes(searchConfig.value.bill_text.toLowerCase()) ||
            val.bill_description
              .toLowerCase()
              .includes(searchConfig.value.bill_text.toLowerCase()))
        );
      });
    });
    const setbillpaycheck = computed(() => {
      return store.state.BillModule.setbillpaycheck;
    });
    return {
      moment,
      tableHeader,
      billList,
      searchConfig,
      route,
      Popuppage, //ฟังค์ชัน event เมื่อ click
      check,
      credit,
      slidepage,
      slidepaynow,
      Done,
      isLoadingPage,
      //setTime,
      bill,
      toView,
      toPay,
      reload,
      setbillpaycheck,
    };
  },
});
